<template>
  <b-row>
    <b-overlay
      :show="show"
      large
      rounded="lg"
      spinner-variant="primary"
    >
      <b-col cols="12">
        <div>
          <form-wizard
            color=" rgba(92, 177, 231, 1) "
            :title="null"
            :subtitle="null"
            shape="square"
            finish-button-text="Submit"
            back-button-text="Previous"
            @on-complete="formSubmitted"
          >

            <!-- accoint details tab -->
            <tab-content
              title="Upload Files"
              :before-change="validationForm"
            >
              <validation-observer
                ref="accountRules"
                tag="form"
              >
                <b-row>
                  <!-- <b-col cols="12" class="mb-2">
                                    <h5 class="mb-0">
                                        Test Fit Submission
                                    </h5>
                                    <small class="text-muted">
                                        Upload your file
                                    </small>
                                </b-col> -->
                  <b-col md="12">
                                        <b-form-group>
                      <label for="InputHelp">Test Fit Request</label>
                            <v-select v-model="tfr" label="tfr" :options="option" :reduce="tfr => tfr.id" ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="CSV"
                      rules="required"
                    >
                      <b-form-group
                        label="CAD Data"
                        label-for="name"
                      >
                        <b-form-file
                          v-model="file"
                          :state="Boolean(file)"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          accept=".csv"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-card-text class="my-1">
                          Selected file: <strong>{{ file ? file.name : '' }}</strong>
                        </b-card-text>
                      </b-form-group>

                    </validation-provider>
                  </b-col>
                  <!-- <b-col md="6">
                                    <validation-provider #default="{ errors }" name="sqft" rules="required">
                                        <b-form-group>
                                            <label for="InputHelp">SQFT</label>
                                            <small class="text-muted">required</small>
                                            <b-input-group append="SQFT">
                                                <b-form-input v-model="sqft" type="number" disabled placeholder="ex.100" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col> -->
                  <b-col md="6">
                    <b-form-group>
                      <label for="InputHelp">Glazing Takeoff (G1)</label>
                      <small class="text-muted"> optional</small>
                      <b-input-group append="LF">
                        <b-form-input
                          v-model="glazing"
                          type="number"
                          placeholder="ex.100.50"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="InputHelp">Drywall Takeoff (W1)</label>
                      <small class="text-muted"> optional</small>
                      <b-input-group append="LF">
                        <b-form-input
                          v-model="drywall"
                          type="number"
                          placeholder="ex.100.50"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="InputHelp">Demising Wall Takeoff (W2)</label>
                      <small class="text-muted"> optional</small>
                      <b-input-group append="LF">
                        <b-form-input
                          v-model="demising"
                          type="number"
                          placeholder="ex.100.00"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="InputHelp">Demolition Reference Image</label>
                      <small class="text-muted"> optional</small>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="demolitionReferenceImage"
                          type="text"
                          placeholder="Demolition Reference Image URL"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="InputHelp">Space Planner Notes</label>
                      <small class="text-muted"> optional</small>
                      <b-form-textarea
                        id="textarea-default"
                        v-model="plannerNotes"
                        placeholder="Add notes for quote reviewer"
                        rows="3"
                        :state="plannerNotes.length <= maxChar"
                        :class="plannerNotes.length > maxChar ? 'text-danger' : ''"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="plannerNotes.length > maxChar ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ plannerNotes.length }}</span> / {{ maxChar }}
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- personal details tab -->
            <tab-content
              title="Review & Submit"
              :before-change="validationFormInfo"
              style="height:500px;    overflow: hidden auto;"
            >
              <validation-observer
                ref="infoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h4 class="mb-0 font-weight-bolder py-1">
                      Furniture, Fixtures & Equipment
                    </h4>
                    <b-table
                      v-if="ffe.length > 0"
                      responsive="sm"
                      :fields="fieldsFFE"
                      :items="ffe"
                    />
                    <h4 v-else>
                      No data to display.
                    </h4>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h4 class="mb-0 font-weight-bolder py-1">
                      Construction
                    </h4>
                    <b-table
                      v-if="construction.length > 0"
                      responsive="sm"
                      :fields="fieldsConstruction"
                      :items="construction"
                    />
                    <h4 v-else>
                      No data to display.
                    </h4>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h4 class="mb-0 font-weight-bolder py-1">
                      Construction Takeoffs
                    </h4>
                    <b-table
                      v-if="takeoffs.length > 0"
                      responsive="sm"
                      :fields="fieldsTakeoffs"
                      :items="takeoffs"
                    />
                    <h4 v-else>
                      No data to display.
                    </h4>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h4 class="mb-0 font-weight-bolder py-1">
                      AV/IT
                    </h4>
                    <b-table
                      v-if="avit.length > 0"
                      responsive="sm"
                      :fields="fieldsAvit"
                      :items="avit"
                    />
                    <h4 v-else>
                      No data to display.
                    </h4>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

          </form-wizard>
        </div>
      </b-col>
      <template #overlay>
        <div class="text-center">
          <lottie-animation path="calculating.json" />
          <h1>Please wait...</h1>
        </div>
      </template>
    </b-overlay>
  </b-row>
</template>

<script>
import {
  FormWizard,
  TabContent,
} from 'vue-form-wizard'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BOverlay,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    vSelect,
  },
  props: ['opp'],
  data() {
    return {
      userName: localStorage.getItem('name'),
      show: false,
      glazing: '',
      demising: '',
      drywall: '',
      sqft: '',
      quoteId: 0,
      file: null,
      required,
      parsedCSV: [],
      location: '',
      opportunityID: '',
      sf_opportunity_id:'',
      avit: [],
      construction: [],
      ffe: [],
      errors:[],
      plannerNotes: '',
      tfr: '',
      demolitionReferenceImage: '',
      takeoffs: [],
      maxChar: 1000,
      fieldsAvit: [{
        key: 'count',
        sortable: true,
      },
      {
        key: 'uid',
        label: 'UID',
        sortable: false,
      },
      {
        key: 'name',
        label: 'Name',
        sortable: false,
      },
      ],
      fieldsConstruction: [{
        key: 'count',
        sortable: true,
      },
      {
        key: 'uid',
        label: 'UID',
        sortable: false,
      },
      {
        key: 'name',
        label: 'Name',
        sortable: false,
      },
      ],
      fieldsFFE: [{
        key: 'count',
        sortable: true,
      },
      {
        key: 'uid',
        label: 'UID',
        sortable: false,
      },
      {
        key: 'name',
        label: 'Name',
        sortable: false,
      },
      ],
      fieldsTakeoffs: [{
        key: 'count',
        label: 'Count',
        sortable: true,
      },
      {
        key: 'linetype',
        label: 'LINETYPE',
        sortable: false,
      },
      {
        key: 'area',
        label: 'AREA',
        sortable: false,
      },
      {
        key: 'length',
        label: 'Length',
        sortable: false,
      },
      ],
    }
  },
  beforeMount() {
    this.sqft = this.opp.rentable_sqft
    this.location = this.opp.geography
    this.opportunityID = this.opp.id
    this.option = this.opp.tfrs
    this.sf_opportunity_id = this.opp.sf_opportunity_id
  },
  methods: {
    formSubmitted() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('w2', this.demising)
      formData.append('w1', this.drywall)
      formData.append('g1', this.glazing)
      formData.append('sqft', this.sqft)
      formData.append('location', this.location)
      formData.append('opportunityID', this.opportunityID)
      formData.append('plannerNotes', this.plannerNotes)
      formData.append('tfr_id', this.tfr)
      formData.append('userName', this.userName)
      formData.append('demolitionReferenceImage', this.demolitionReferenceImage)
      this.show = true
      this.$http.post('/quote-calculations', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
            this.showToast('success', 'Quote Created')
            const quoteId = response.data.id
            this.$router.push(`/opportunities/${this.sf_opportunity_id}/${quoteId}`)
        })
        .catch(() => {
          this.show = false
          this.showToast('danger', 'Error')
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
            const formData = new FormData()
            formData.append('file', this.file)
            formData.append('w2', this.demising)
            formData.append('w1', this.drywall)
            formData.append('g1', this.glazing)
            formData.append('sqft', this.sqft)
            formData.append('location', this.location)
            this.show = false
            this.$http.post('/csv-review', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
              .then(response => {
                this.show = false
                this.parsedCSV = response.data
                this.avit = this.parsedCSV.avit
                this.construction = this.parsedCSV.construction
                this.ffe = this.parsedCSV.ffe
                this.takeoffs = this.parsedCSV.takeoffs
              })
              .catch(error => {
                this.show = false
                this.showToast('danger', error.response.data.message)
              })
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
